import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import authReducer from '../auth/authSlice';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'USER_ID',
    'serviceCenter',
    'workplace',
    'error',
    'orgGuid',
    'orgName',
    'ticketInProgress',
    'ticketTime',
  ],
};

const persistedReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export const persistor = persistStore(store);
